import React from 'react'
import Box from '@mui/material/Box'
import { connect } from 'react-redux'
import Layout from '../templates/Master'

const Error404 = ({ location }) => {
    return (
        <Layout location={location}>
            <Box>
                404 Error
            </Box>
        </Layout>
    )
}


export default connect()(Error404)
